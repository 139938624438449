

























import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

@Component({})
export default class ProgressBar extends Vue {
  @Prop({}) private progress!: number;
  @Prop({ default: false }) private useSlot!: boolean;

  private stop() {
    this.$emit('stop-progress');
  }
}
